<template>
    <div>
        <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
            <el-form-item prop="bank_account_type" label="账户类型">
                <el-select v-model="form.bank_account_type" clearable @change="bank_account_typeChange" style="width: 400px">
                    <el-option v-for="item in bank_account_typeDic"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="tips">
                    <span>1.若主体为企业/政府机关/事业单位/社会组织，可填写：74-对公账户。</span>
                    <span>2.主体为小微/个人卖家，可选择：75-对私账户。</span>
                    <span>3.若主体为个体工商户，可填写：74-对公账户、75-对私账户。</span>
                </div>
            </el-form-item>
            <el-form-item prop="bank_alias_code" label="开户银行">
                <el-select v-model="form.bank_alias_code" clearable filterable remote :remote-method="filterBank" @change="account_bankChange" style="width: 400px">
                    <el-option v-for="(item,index) in account_bankDic"
                        :key="index"
                        :label="item.bankAlias"
                        :value="item.bankAliasCode">
                    </el-option>
                    <div class="a-flex-rcc">
                        <el-button type="text" @click="showMoreBank">加载更多</el-button>
                    </div>
                </el-select>
            </el-form-item>
            <el-form-item label="开户名称" prop="account_name">
                <el-input maxlength="100" v-model="form.account_name" placeholder="请输入开户名称" style="width: 400px"></el-input>
                <div class="tips">
                    <span>1.选择经营者个人银行卡时，开户名称必须与身份证姓名一致。</span>
                    <span>2.选择对公账户时，开户名称必须与营业执照上的“商户名称”一致。</span>
                </div>
            </el-form-item>
            <el-form-item label="开户人身份证号" prop="contact_id_card_number">
                <el-input maxlength="100" v-model="form.contact_id_card_number" placeholder="请输入开户人身份证号" style="width: 400px"></el-input>
                <div class="tips">
                    <span>1.开户人身份证号必须与身份证姓名一致。</span>
                </div>
            </el-form-item>
            <el-form-item label="开户人手机号" prop="mobile_phone">
                <el-input maxlength="100" v-model="form.mobile_phone" placeholder="请输入开户人手机号" style="width: 400px"></el-input>
                <div class="tips">
                    <span>1.开户人手机号与银行卡预留手机号一致。</span>
                </div>
            </el-form-item>
            <el-form-item label="开户银行省市编码" prop="bank_wx_address_code">
                <el-select v-model="form.bank_address_code_province" clearable @change="bank_address_code_provinceChange">
                    <el-option v-for="(item,index) in bank_address_code_provinceDic"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="form.bank_wx_address_code" clearable @change="bank_address_code_CityChange" style="margin-left:10px">
                    <el-option v-for="item in bank_address_code_CityDic"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="bank_branch_id" label="开户银行全称（含支行）">
                <el-select v-model="form.bank_branch_id" clearable filterable @change="bank_nameChange"  style="width: 400px">
                    <el-option v-for="(item,index) in bank_nameDic"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    <div class="a-flex-rcc">
                        <el-button type="text" @click="showMoreBankBranch">加载更多</el-button>
                    </div>
                </el-select>
            </el-form-item>
            <el-form-item label="银行账号" prop="account_number">
                <el-input maxlength="100" v-model="form.account_number" placeholder="请输入银行账号" style="width: 400px"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input
                    style="width: 400px;"
                    type="textarea"
                    :autosize="{ minRows: 4 }"
                    placeholder="请输入内容"
                    maxlength="20"
                    show-word-limit
                    v-model="form.remark">
                </el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                form: {
                    bank_account_type: '',//账户类型
                    account_bank: '',//开户银行	
                    bank_alias: '',//开户银行别名
                    bank_alias_code: '',//开户银行别名code
                    account_name: '',//开户名称	
                    bank_address_code_province: '',
                    bank_wx_address_code: '',//开户银行省市编码
                    bank_branch_id: '',//开户银行联行号
                    bank_name: '',//开户银行全称 （含支行）
                    account_number: '',//银行账号		
                    contact_id_card_number: '',//开户人身份证号
                    mobile_phone: '',//开户人手机号
                    remark: ''
                },
                rules: {
                    bank_account_type: [{required: true, message:'请选择账户类型', trigger: 'change'}],//账户类型
                    bank_alias_code: [{required: true, message:'请选择开户银行', trigger: 'change'}],//开户银行	
                    account_name: [{required: true, message:'请输入开户名称', trigger: 'blur'}],//开户名称	
                    bank_wx_address_code: [{required: true, message:'请选择开户银行省市', trigger: 'change'}],//开户银行省市编码
                    bank_branch_id: [{required: true, message:'请选择开户银行全称 （含支行）', trigger: 'change'}],//开户银行全称 （含支行）id （即开户银行联行号）
                    account_number: [{required: true, message:'请输入银行账号', trigger: 'blur'}],//银行账号	
                },
                bank_account_typeDic: [],//账户类型字典
                account_bankDic: [],//开户银行字典
                bank_address_code_provinceDic: [],//开户银行省字典
                bank_address_code_CityDic: [],//开户银行市字典
                bank_nameDic: [],//开户银行全称 （含支行）字典
                bankCorporate: [],//对公银行列表
                bankPersonal: [],//对私银行列表
                bankPageNum: 1,
                bankBranchPageNum: 0,
                limit: 100,
                queryBankName: '',
            };
        },
        // watch:{
        //     dialogVisible (val) {
        //         if(this.$refs['form'] && !val){
        //             this.$refs['form'].resetFields()
        //         }
        //     },
        // },
        created () {
            this.$getDic('bank_account_type','select').then(res=>{ this.bank_account_typeDic = res; })
            this.getWXBankProvinces()
        },
        methods:{
            //账户类型改变
            bank_account_typeChange (e) {
                this.bankPageNum = 1
                this.queryBankName = ''
                if(e == 75){
                    this.getWXBankPersonal()
                }else{
                    this.getWXBankCorporate()
                }
                this.form.account_bank = ''
                this.form.bank_wx_address_code = ''
                this.form.bank_address_code_province = ''
                this.form.bank_branch_id = ''
                this.form.bank_name = ''
                this.bank_address_code_CityDic = []
                this.bank_nameDic = []
            },
            // 开户银行改变
            account_bankChange (e) {
                this.queryBankName = ''
                let c_bank = this.account_bankDic.find(res=>{
                    return res.bankAliasCode == e
                })
                if(c_bank) {
                    // 获取开户银行名称
                    this.form.account_bank = c_bank.accountBank
                    // 获取开户银行名称别名
                    this.form.bank_alias = c_bank.bankAlias
                }
                this.form.bank_wx_address_code = ''
                this.form.bank_address_code_province = ''
                this.form.bank_branch_id = ''
                this.form.bank_name = ''
                this.bank_address_code_CityDic = []
                this.bank_nameDic = []
            },
            bank_address_code_provinceChange () {
                this.form.bank_wx_address_code = ''
                this.form.bank_branch_id = ''
                this.form.bank_name = ''
                this.bank_address_code_CityDic = []
                this.bank_nameDic = []
                this.bankBranchPageNum = 0
                this.getWXBankCities()
            },
            bank_address_code_CityChange () {
                this.form.bank_branch_id = ''
                this.form.bank_name = ''
                this.bank_nameDic = []
                this.bankBranchPageNum = 0
                this.getWXBankBranches()
            },
            bank_nameChange (e) {
                this.form.bank_name = this.bank_nameDic.find(res=>{
                    return res.value == e
                }).label
            },
            // 查询银行支行
            getWXBankBranches () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getWXBankBranches,
                    method: "post",
                    params: {
                        bank_alias_code: this.form.bank_alias_code,
                        city_code: this.form.bank_wx_address_code,
                        offset: this.bankBranchPageNum*this.limit,
                        limit: this.limit
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        let arr = res.result.data.data.map(item=>{
                            return {
                                ...item,
                                label: item.bank_branch_name,
                                value: item.bank_branch_id
                            }
                        })
                        this.bank_nameDic = this.bank_nameDic.concat(arr)
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            },
            // 查询对公账户银行
            getWXBankCorporate () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getWXBankCorporate,
                    method: "post",
                    params: {
                        pageNum: this.bankPageNum,
                        pageSize: this.limit,
                        bankName: this.queryBankName?this.queryBankName:''
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        let arr = res.result.data.list.map(item=>{
                            return {
                                ...item,
                                label: item.accountBank,
                                value: item.accountBankCode
                            }
                        })
                        if(this.bankPageNum == 1){
                            this.bankCorporate = arr
                        }else{
                            this.bankCorporate = this.bankCorporate.concat(arr)
                        }
                        this.setBankDic()
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            },
            // 查询对私账户银行
            getWXBankPersonal () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getWXBankPersonal,
                    method: "post",
                    params: {
                        pageNum: this.bankPageNum,
                        pageSize: this.limit,
                        bankName: this.queryBankName?this.queryBankName:''
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        let arr = res.result.data.list.map(item=>{
                            return {
                                ...item,
                                label: item.accountBank,
                                value: item.accountBankCode
                            }
                        })
                        if(this.bankPageNum == 1){
                            this.bankPersonal = arr
                        }else{
                            this.bankPersonal = this.bankPersonal.concat(arr)
                        }
                        this.setBankDic()
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            },
            setBankDic () {
                if(this.form.bank_account_type == 75){
                    this.account_bankDic = this.bankPersonal
                }else{
                    this.account_bankDic = this.bankCorporate
                }
            },
            filterBank (query) {
                this.bankPageNum = 1
                this.queryBankName = query
                if(this.form.bank_account_type == 75){
                    this.getWXBankPersonal()
                }else{
                    this.getWXBankCorporate()
                }
            },
            // 加载更多银行
            showMoreBank () {
                this.bankPageNum++
                if(this.form.bank_account_type == 75){
                    this.getWXBankPersonal()
                }else{
                    this.getWXBankCorporate()
                }
            },
            // 获取银行省
            getWXBankProvinces () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getWXBankProvinces,
                    method: "get",
                }).then(res => {
                    if (res.result.code == 0) {
                        this.bank_address_code_provinceDic = res.result.data.data.map(item=>{
                            return {
                                ...item,
                                label: item.province_name,
                                value: item.province_code
                            }
                        })
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            },
            // 获取银行市
            getWXBankCities () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getWXBankCities,
                    method: "get",
                    params: {
                        provinceCode: this.form.bank_address_code_province
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        this.bank_address_code_CityDic = res.result.data.data.map(item=>{
                            return {
                                ...item,
                                label: item.city_name,
                                value: item.city_code
                            }
                        })
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            },
            // 加载更多支行
            showMoreBankBranch () {
                this.bankBranchPageNum++
                this.getWXBankBranches()
            },
            getCodeByName () {
                return new Promise((resolve, reject) => {
                    let provinceName = this.bank_address_code_provinceDic.find(res=>{
                        return res.value == this.form.bank_address_code_province
                    }).label
                    let cityName = this.bank_address_code_CityDic.find(res=>{
                        return res.value == this.form.bank_wx_address_code
                    }).label
                    let directCity = [10, 21, 22, 23]  // 微信省市编码，直辖市编码code
                    this.$Axios._post({
                        url: this.$Config.apiUrl.getCodeByName,
                        method: "post",
                        params: {
                            cityName: directCity.indexOf(this.form.bank_wx_address_code) == -1 ? cityName : null,// 选中直辖市时不传市名称
                            provinceName: provinceName
                        }
                    }).then(res => {
                        if (res.result.code == 0) {
                            resolve(res.result.data.id)
                        } else {
                            this.$message.error(res.result.message)
                            reject()
                        }
                    }).catch(err=>{
                        reject()
                    })
                })
                
            },
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        let bank_address_code = await this.getCodeByName()
                        // this.$message.success('操作成功')
                        let reqData = {
                            ...this.form,
                            bank_address_code: bank_address_code
                        }
                        this.$emit('change',reqData);
                        // this.dialogVisible = false
                    }
                })
                
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>