<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <div class="withdrawal-content">
            <!-- <div class="title a-c-master a-fs-16 a-fw-700">添加银行卡</div> -->
            <el-tabs class="a-mt-16 devicetabs" v-model="activeName">
                <el-tab-pane label="添加玖电银行卡" name="0"></el-tab-pane>
                <el-tab-pane label="添加宝付银行卡" name="1"></el-tab-pane>
            </el-tabs>
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="150px" v-if="activeName == '0'">
                <el-form-item label="账户类型" prop="isPrivateAccount">
                    <el-select v-model="form.isPrivateAccount" placeholder="请选择" style="width:400px">
                        <el-option label="对公账户" :value="0"></el-option>
                        <el-option label="个人账户" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开户名" prop="accountName">
                    <el-input style="width:400px;" v-model="form.accountName" placeholder="请输入">
                    </el-input>
                </el-form-item>
                <el-form-item label="认证短信接收手机" prop="mobile">
                    <el-input style="width:400px;" v-model="form.mobile" placeholder="请输入">
                    </el-input>
                </el-form-item>
                <el-form-item label="开户行" prop="bankCode">
                    <el-select v-model="form.bankCode" placeholder="请选择" @change="bankChange" style="width:400px">
                        <el-option v-for="(item, index) in bankList" :key="item.bankCode" :label="item.bankName"
                            :value="item.bankCode"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支行所在省份" prop="provinceCode">
                    <el-select v-model="form.provinceCode" placeholder="请选择" @change="bankProvinceChange"
                        style="width:400px">
                        <el-option v-for="(item, index) in provinceList" :key="item.provinceCode"
                            :label="item.provinceName" :value="item.provinceCode"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支行所在地区" prop="areaCode">
                    <el-select v-model="form.areaCode" placeholder="请选择" @change="bankAreaChange" style="width:400px">
                        <el-option v-for="(item, index) in areaList" :key="item.areaCode" :label="item.areaName"
                            :value="item.areaCode"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开户行支行" prop="branchBankCode">
                    <el-select v-model="form.branchBankCode" filterable placeholder="请选择(可搜索)"
                        @change="branchBankChange" style="width:400px">
                        <el-option v-for="(item, index) in bankBranchList" :key="item.branchCode"
                            :label="item.branchName" :value="item.branchCode"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="银行账号" prop="accountNumber">
                    <el-input style="width:400px;" v-model="form.accountNumber"
                        @input="form.accountNumber = form.accountNumber.replace(/[^\w\/]/ig, '')" placeholder="请输入">
                    </el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="cardNo">
                    <el-input style="width:400px;" v-model="form.cardNo"
                        @input="form.cardNo = form.cardNo.replace(/[^\w\/]/ig, '')" placeholder="请输入">
                    </el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input
                        style="width: 400px;"
                        type="textarea"
                        :autosize="{ minRows: 4 }"
                        placeholder="请输入内容"
                        maxlength="20"
                        show-word-limit
                        v-model="form.remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <le-card-add ref="cardAddBf" @change="submitBf" v-if="activeName == '1'"></le-card-add>
            <le-service-privacy v-model="isRead"></le-service-privacy>
        </div>
        <div class="a-line-t-e0 footerBox">
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即添加</el-button>
            <el-button class="a-ml-24 a-mt-15 " @click="cancelSubmit" style="margin-left:16px;">
                &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
        </div>
    </div>
</template>

<script>
import leCardAdd from '../components/page/le-card-add.vue';
export default {
    components: {
        leCardAdd
    },
    data() {
        var checkPhone = (rule, value, callback) => {
            let reg = RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
            if (reg.test(value)) {
                callback();
            } else {
                callback(new Error('手机号格式不正确'));
            }
        };
        var isNumAletter = (rule, value, callback) => {
            if (/^[0-9a-zA-Z]/.test(value)) {
                callback();
            } else {
                callback(new Error('输入格式不正确'));
            }
        };
        return {
            activeName: '0',
            form: {
                isPrivateAccount: '',
                accountName: '',//开户名
                accountNumber: '',
                bankCode: '',
                provinceCode: '',
                areaCode: '',
                branchBankCode: '',
                mobile: '',
                cardNo: '',
                bankId: '',//银行id（支行id）
                remark: '',
            },
            rules: {
                isPrivateAccount: [{ required: true, message: '请选择账户类型', trigger: 'change' }],
                accountName: [{ required: true, message: '请输入开户名', trigger: 'blur' }],//开户名
                accountNumber: [{ required: true, message: '请输入银行账号', trigger: 'blur' }, { validator: isNumAletter, trigger: 'blur' }],
                bankCode: [{ required: true, message: '请选择开户行', trigger: 'change' }],
                provinceCode: [{ required: true, message: '请选择开户行所在省份', trigger: 'change' }],
                areaCode: [{ required: true, message: '请选择开户行所在地区', trigger: 'change' }],
                branchBankCode: [{ required: true, message: '请选择支行', trigger: 'change' }],
                mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { validator: checkPhone, trigger: 'blur' }],
                cardNo: [{ required: true, message: '请输入身份证号', trigger: 'blur' }, { validator: isNumAletter, trigger: 'blur' }],
            },
            bankList: [],//开户行列表
            provinceList: [],// 开户行所在省份
            areaList: [],// 开户行所在地区
            bankBranchList: [],//开户行支行
            isRead: false,//是否同意用户协议
        }
    },
    mounted() {
        this.getBankList()
    },
    methods: {
        getBankList() {
            this.$Axios._post({
                url: this.$Config.apiUrl.getDictBankList,
                method: "post",
                params: {
                    "pageNum": 0,
                    "pageSize": 0,
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.bankList = res.result.data.list
                    // this.getDictBankProvince()
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 获取开户行所在省份
        getDictBankProvince() {
            this.$Axios._post({
                url: this.$Config.apiUrl.getDictBankProvince,
                method: "post",
                params: {
                    bankCode: this.form.bankCode,
                    "pageNum": 0,
                    "pageSize": 0,
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.provinceList = res.result.data.list
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 获取开户行所在地区
        getDictBankArea() {
            let provinceName = this.provinceList.find(res => {
                return res.provinceCode == this.form.provinceCode
            }).provinceName
            this.$Axios._post({
                url: this.$Config.apiUrl.getDictBankArea,
                method: "post",
                params: {
                    bankCode: this.form.bankCode,
                    provinceName: provinceName,
                    provinceCode: this.form.provinceCode,
                    "pageNum": 0,
                    "pageSize": 0,
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.areaList = res.result.data.list
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 获取开户行支行
        getDictBankBranchList() {
            let provinceName = this.provinceList.find(res => {
                return res.provinceCode == this.form.provinceCode
            }).provinceName
            let areaName = this.areaList.find(res => {
                return res.areaCode == this.form.areaCode
            }).areaName
            this.$Axios._post({
                url: this.$Config.apiUrl.getDictBankBranchList,
                method: "post",
                params: {
                    bankCode: this.form.bankCode,
                    provinceName: provinceName,
                    provinceCode: this.form.provinceCode,
                    areaCode: this.form.areaCode,
                    areaName: areaName,
                    "pageNum": 0,
                    "pageSize": 0,
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.bankBranchList = res.result.data.list
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 选择开户行
        bankChange(datas) {
            this.form.bankId = this.bankList.find(res => {
                return res.bankCode == datas
            }).id
            this.form.provinceCode = ''
            this.form.areaCode = ''
            this.form.areaCode = ''
            this.form.branchBankCode = ''
            this.provinceList = []// 开户行所在省份
            this.areaList = []// 开户行所在地区
            this.bankBranchList = []//开户行支行
            this.getDictBankProvince()
        },
        // 选择省份
        bankProvinceChange() {
            this.form.areaCode = ''
            this.form.areaCode = ''
            this.form.branchBankCode = ''
            this.areaList = []// 开户行所在地区
            this.bankBranchList = []//开户行支行
            this.getDictBankArea()
        },
        // 选择地区
        bankAreaChange() {
            this.form.branchBankCode = ''
            this.bankBranchList = []//开户行支行
            this.getDictBankBranchList()
        },
        branchBankChange(datas) {

        },
        submit() {
            if (!this.isRead) {
                this.$confirm('请先同意《用户服务协议》和《隐私政策》。', '温馨提示', {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    type: 'warning'
                }).then(_ => {

                }).catch(_ => { })
            } else {
                if(this.activeName == '0') {
                    this.$refs['form'].validate((valid) => {
                        if (valid) {
                            let reqData = {
                                ...this.form,
                                
                                authChannel: 0
                            }
                            this.bindBank(reqData)
                        } else {
                            return false
                        }
                    })
                }else {
                    // 添加宝付银行卡
                    this.$refs['cardAddBf'].submit()
                }
            }
        },
        submitBf (datas) {
            let reqData = {
                ...datas,
                // bankName: datas.account_bank,
                // accountName: datas.account_name,
                // accountNumber: datas.account_number,
                // bankAccountType: datas.bank_account_type,
                // provinceCode: datas.bank_address_code,
                authChannel: 4
            }
            this.bindBank(reqData)
        },
        cancelSubmit() {
            this.$router.back()
            // this.$confirm('是否确认取消?', '温馨提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(_ => {
            //     this.$router.back()
            // }).catch(_ => { })
        },
        bindBank(reqData) {
            this.$confirm('是否确认提交?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(_ => {
                let apiUrl
                if(this.activeName == '0') {
                    apiUrl = this.$Config.apiUrl.bindBankAccount
                }else {
                    apiUrl = this.$Config.apiUrl.bindBankAccountNew
                }
                this.$Axios._post({
                    url: apiUrl,
                    method: "post",
                    params: {
                        ...reqData
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        this.$route.meta.hash = "del";
                        this.$router.replace({
                            path: '/businessCenter/business-center',
                            query: {
                                tabs: 'yhzh'
                            }
                        })
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            }).catch(_ => { })
        },
    }
}
</script>

<style lang="scss" scoped>
.withdrawal-content {
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    font-size: 14px;
    padding: 0 25px 100px;
}

.title {
    padding: 25px 0 17px
}

.footerBox {
    left: 255px;
    right: 43px;
    position: fixed;
    bottom: 0;
    background: #fff;
    margin-bottom: 20px;
    padding-bottom: 20px
}
/deep/ .el-tabs__item {
    // color: #007aff;
    font-size: 14px;
}

/deep/ .el-tabs__item.is-active {
    color: #007AFF;
    font-weight: bold;
}

/deep/ .el-tabs__nav-wrap::after {
    background: none !important;
}

/deep/ .el-tabs__header {
    padding: 0 0 0;
    background: #fff;
}
</style>